<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                d="M12.0023 15.5C11.048 15.5 10.1328 15.1313 9.45797 14.4749C8.78318 13.8185 8.40408 12.9283 8.40408 12C8.40408 11.0717 8.78318 10.1815 9.45797 9.52513C10.1328 8.86875 11.048 8.5 12.0023 8.5C12.9566 8.5 13.8718 8.86875 14.5466 9.52513C15.2214 10.1815 15.6005 11.0717 15.6005 12C15.6005 12.9283 15.2214 13.8185 14.5466 14.4749C13.8718 15.1313 12.9566 15.5 12.0023 15.5ZM19.6407 12.97C19.6818 12.65 19.7127 12.33 19.7127 12C19.7127 11.67 19.6818 11.34 19.6407 11L21.8099 9.37C22.0052 9.22 22.0566 8.95 21.9333 8.73L19.8772 5.27C19.7538 5.05 19.4762 4.96 19.25 5.05L16.6902 6.05C16.1556 5.66 15.6005 5.32 14.9528 5.07L14.5724 2.42C14.5313 2.18 14.3154 2 14.0584 2H9.94616C9.68915 2 9.47326 2.18 9.43214 2.42L9.05176 5.07C8.40408 5.32 7.84894 5.66 7.31435 6.05L4.7545 5.05C4.52832 4.96 4.25075 5.05 4.12738 5.27L2.07128 8.73C1.93763 8.95 1.99931 9.22 2.19464 9.37L4.36384 11C4.32271 11.34 4.29187 11.67 4.29187 12C4.29187 12.33 4.32271 12.65 4.36384 12.97L2.19464 14.63C1.99931 14.78 1.93763 15.05 2.07128 15.27L4.12738 18.73C4.25075 18.95 4.52832 19.03 4.7545 18.95L7.31435 17.94C7.84894 18.34 8.40408 18.68 9.05176 18.93L9.43214 21.58C9.47326 21.82 9.68915 22 9.94616 22H14.0584C14.3154 22 14.5313 21.82 14.5724 21.58L14.9528 18.93C15.6005 18.67 16.1556 18.34 16.6902 17.94L19.25 18.95C19.4762 19.03 19.7538 18.95 19.8772 18.73L21.9333 15.27C22.0566 15.05 22.0052 14.78 21.8099 14.63L19.6407 12.97Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
